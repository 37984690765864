
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { QuoteActions } from "@/store/vm/quote/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import CropperComponent from "@/../src/components/upload/cropper.vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "upsert-quote",
  components: {
    cropper: CropperComponent,
  },
  props: {
    quoteId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["reset"],
  methods: {
    activateCropper: function () {
      (this.$refs["coverCropper"] as any).selectFile();
    },
    onUploadCoverFail: function (response) {
      Swal.fire({
        position: "top-end",
        text: "Đã xảy ra lỗi upload ảnh, vui lòng thử lại sau!",
        icon: "error",
        buttonsStyling: false,
        timer: 2000,
        width: 400,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    // Get params from url
    const quoteId =
      route.params["quoteId"] === "create" ? false : route.params["quoteId"];

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const content = ref<string>("");

    const defaultData = {
      id: "",
      image: "",
      quote: "",
      point: 0,
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Danh ngôn", ["Hệ thống", "Danh ngôn"]);
      MenuComponent.reinitialization();

      // get detail
      if (quoteId) {
        const detail = await store
          .dispatch(QuoteActions.GET_DETAIL, quoteId)
          .catch((e) => {
            console.log(e);
          });

        formData.value = detail;
      }
    });

    const formData = ref({ ...defaultData });

    const rules = ref({
      quote: [
        {
          required: true,
          message: "Yêu cầu nhập nội dung",
          trigger: "change",
        },
      ],
    });

    const isCreate = !quoteId;

    const actionCreate = (par) => {
      return store.dispatch(QuoteActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(QuoteActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          if (isCreate) {
            stepResult = await actionCreate(formData.value);
          } else {
            stepResult = await actionUpdate(formData.value);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(() => {
              router.push({ name: "Quote" });
            });
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const onUploadCoverSuccess = (imageUrl) => {
      formData.value.image = imageUrl;
    };

    return {
      isCreate,
      onUploadCoverSuccess,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      content,
    };
  },
});
